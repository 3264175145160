html {
  min-height: 100%;
}

body {
  min-height: 100%;
  background: white no-repeat bottom center;
}

.hidden {
  display: none;
}

#main {
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  position: relative;
  min-height: max(calc(100vh - 60px), 400px);
  padding-bottom: 40px;
}

footer {
  position: absolute;
  bottom: 10px;
}

header {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

header h1 {
  font: bold 24px 'acrossoverepisode-font';
  flex-grow: 1;
}

header label {
  display: flex;
  align-items: center;
  position: relative;
}

header input {
  margin: 0 4px;
}

header label #clear {
  position: absolute;
  border-radius: 50%;
  right: 10px;
  width: 16px;
  height: 16px;
  padding: 0;
  opacity: 0.3;
  background: #ccc;
}

header label #clear:after{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  line-height: 100%;
  content: "\00d7";
}

header label #clear:hover,
header label #clear:focus {
  opacity: 1;
}

header button {
  height: 30px;
}

button,
.button {
  display: inline-block;
  border: none;
  font-size: 14px;
  padding: 4px 10px;
  background: #e9e9ed;
  color: black;
  text-decoration: none;
}

button:hover,
.button:hover {
  background: #d0d0d7;
}

ul {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}

ul, ul li {
  padding: 0;
  list-style: none;
}

.searchResult {
  position: relative;
  margin: 0 auto;
}

.searchResult span {
  position: absolute;
  color: yellow;
  bottom: 10px;
  width: 100%;
  font-size: 13px;
  text-align: center;
  text-shadow:
    -1px -1px 0 #000,
     0   -1px 0 #000,
     1px -1px 0 #000,
     1px  0   0 #000,
     1px  1px 0 #000,
     0    1px 0 #000,
    -1px  1px 0 #000,
    -1px  0   0 #000;
  left: 0;
  white-space: pre-line;
}

img.thumbnail {
  width: 180px;
  height: 101px;
  display: block;
}

#selectedItem .back {
  margin-bottom: 8px;
}

#selectedItem button {
  display: block;
}

#selectedItem canvas {
  display: none;
}

#selectedItem #frameNavigation {
  display: flex;
}

#selectedItem #frameNavigation .button {
  display: block;
}

#selectedItem #frameNavigation .button:nth-child(2) {
  margin-left: auto;
}

#selectedItem img {
  display: block;
  width: 100%;
}

#selectedItem textarea {
  width: 100%;
}

footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

footer ul {
  margin: 0 0 0 8px;
  flex-grow: 1;
}

footer a {
  background: white;
}
